// app/client/src/pages/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../utils/login-api';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        const response = await registerUser({ email, password });
        if (response.message === "회원가입이 완료되었습니다.") {
            // 회원가입 성공 후 리다이렉트
            history.push('/login');
        } else {
            alert(response.detail);
        }
    };

    return (
        <form onSubmit={handleRegister}>
            <h2>회원가입</h2>
            <input
                type="email"
                placeholder="이메일"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="비밀번호"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <button type="submit">회원가입</button>
        </form>
    );
};

export default Register;