// app/client/src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // useHistory 대신 useNavigate 사용
import { loginUser } from '../utils/login-api'; // API 호출 함수

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // useNavigate 훅 사용

    const handleLogin = async (e) => {
        e.preventDefault();
        const response = await loginUser({ email, password });
        if (response.message === "로그인 성공") {
            // 로그인 성공 후 홈으로 리다이렉트
            navigate('/'); // navigate를 사용하여 리다이렉트
        } else {
            alert(response.detail);
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h2>로그인</h2>
            <form onSubmit={handleLogin}>
                <input
                    type="email"
                    placeholder="이메일"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{ padding: '10px', width: '300px', marginBottom: '10px' }}
                />
                <br />
                <input
                    type="password"
                    placeholder="비밀번호"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{ padding: '10px', width: '300px', marginBottom: '10px' }}
                />
                <br />
                <button type="submit" style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px' }}>
                    로그인
                </button>
            </form>
        </div>
    );
};

export default Login;