// app/client/src/pages/ResetPassword.js
import React, { useState } from 'react';
import { resetPassword } from '../utils/login-api';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handleReset = async (e) => {
        e.preventDefault();
        const response = await resetPassword({ email, new_password: newPassword });
        alert(response.message);
    };

    return (
        <form onSubmit={handleReset}>
            <h2>비밀번호 재설정</h2>
            <input
                type="email"
                placeholder="이메일"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="새 비밀번호"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
            />
            <button type="submit">비밀번호 재설정</button>
        </form>
    );
};

export default ResetPassword;