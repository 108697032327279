// app/client/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Content from './components/Content'; // Content 컴포넌트
import Login from './pages/Login'; // Login 컴포넌트 추가
import Register from './pages/Register'; // Register 컴포넌트 추가
import ResetPassword from './pages/ResetPassword'; // ResetPassword 컴포넌트 추가
import './styles/App.css';

const App = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Content />} /> {/* Content 컴포넌트를 렌더링 */}
                <Route path="/login" element={<Login />} /> {/* Login 컴포넌트를 렌더링 */}
                <Route path="/register" element={<Register />} /> {/* Register 컴포넌트를 렌더링 */}
                <Route path="/reset-password" element={<ResetPassword />} /> {/* ResetPassword 컴포넌트를 렌더링 */}
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;