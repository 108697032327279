// app/client/email-client/src/utils/api.js

import API_URL from './server-api';

const loginUser = async (data) => {
    const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return response.json();
};

const registerUser = async (data) => {
    const response = await fetch(`${API_URL}/auth/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return response.json();
};

const resetPassword = async (data) => {
    const response = await fetch(`${API_URL}/auth/reset_password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return response.json();
};

export { loginUser, registerUser, resetPassword }; // 여러 함수 내보내기