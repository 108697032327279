// app/client/src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'

const Header = () => {
    return (
        <header style={{
            backgroundColor: '#2ecc71',
            padding: '20px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            marginBottom: '20px'
        }}>
            <img src="/logo.png" alt="Stockestic 로고" style={{ width: '30px', marginRight: '10px' }} />
            <h1 style={{ margin: 0 }}>Stockestic</h1>
            <nav style={{ marginLeft: 'auto' }}>
                <ul style={{ listStyle: 'none', display: 'flex', gap: '15px', margin: 0 }}>
                    <li>
                        <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>홈</Link>
                    </li>
                    <li>
                        <Link to="/login" style={{ color: 'white', textDecoration: 'none' }}>로그인</Link>
                    </li>
                    <li>
                        <Link to="/register" style={{ color: 'white', textDecoration: 'none' }}>회원가입</Link>
                    </li>
                    <li>
                        <Link to="/reset-password" style={{ color: 'white', textDecoration: 'none' }}>비밀번호 재설정</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;